@import 'vars.scss';

.presentation {
  height: 100vh;
  overflow: hidden;
  padding: 0rem 20rem;
  font-size: 36px;

  .slide-content {
    position: relative;
    height: 100vh;
    background: #fff;
    color: #333;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    .slide-inner {
      width: 100%;
    }

    p {
      margin-bottom: 1.25em;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
      font-family: $head-font;
      margin: 2.75rem 0 1rem;
      color: #222222;
      font-weight: bolder;
      line-height: 1.15;
      letter-spacing: -3.3px;
    }

    h1 {
      margin-top: 0;
      font-size: 3.052em;
    }

    h2 {
      font-size: 2.441em;
    }

    h3 {
      font-size: 1.953em;
    }

    h4 {
      font-size: 1.563em;
    }

    h5 {
      font-size: 1.25em;
    }

    small,
    .text_small {
      font-size: 0.8em;
    }

    img {
      object-fit: contain;
      display: block;
      max-width: 70%;
      max-height: 70%;

      &.emoji {
        display: inline-block;

        width: 36px;
        height: 36px;
      }
    }

    table {
      width: 100%;
      border-spacing: 0rem;
      text-align: left;
      td,
      th {
        padding: 0.5rem;
      }

      thead {
        color: #444444;

        th {
          border-bottom: 2px solid #eeeeee;
        }
      }

      tbody tr:nth-child(even) {
        background-color: $light;
      }
    }

    pre {
      background: $lighter;
      padding: 1rem;
      border-left: 16px solid #eeeeee;
    }
  }
  .current-slide {
    z-index: 10;
  }
  .next-slide {
    // top: 10rem;
    z-index: 1;
  }
  .next-slide-enter {
    transform: translateY(-80vh);
    opacity: 0;
  }
  .next-slide-enter-active {
    opacity: 1;
    transform: translateY(-100vh);
    transition: all 1000ms ease;
  }
  .next-slide-exit {
    // display: none;
  }
  .next-slide-exit-active {
    // display: none;
  }

  .current-slide-enter {
    // display: none;
  }
  .current-slide-enter-active {
    // display: none;
  }
  .current-slide-exit {
    opacity: 1;
    transform: translateY(0vh);
  }
  .current-slide-exit-active {
    opacity: 0;
    transform: translateY(-10vh);
    transition: all 1000ms ease;
  }
}
