$main-font: 'Inter', sans-serif;
$head-font: 'Inter', sans-serif;

$primary-color: #ff002eff;
$secondary-color: #2f07ffff;

$primary-color-light: #f2cbbd;
$secondary-color-light: #a9b5d9;
$secondary-color-dark: #343140;

$light: #f5f5f5;
$lighter: #fafafa;
