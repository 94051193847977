// Theme
@import url('https://rsms.me/inter/inter.css');
html {
  font-family: 'Inter', sans-serif;
}
@supports (font-variation-settings: normal) {
  html {
    font-family: 'Inter var', sans-serif;
  }
}

* {
  box-sizing: border-box;
}

@import 'vars.scss';
@import 'presentation.scss';
@import 'github-gist.css';

html,
body {
  margin: 0;
  background: #fff;
  min-height: 100vh;
}

.App {
  font-family: $main-font, sans-serif;
  font-weight: 500;
  font-size: 24px;

  .start {
    font-family: $head-font;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    max-width: 1000px;
    align-items: center;

    .flex {
      display: flex;

      .info {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        text-align: left;

        a {
          color: inherit;
          text-decoration: none;
          border-bottom: 2px solid $primary-color;
        }
      }
    }

    #icon {
      height: 256px;
      width: 256px;
    }
    h1 {
      font-size: 5rem;
      margin: 0;
      margin-bottom: -1rem;
    }

    .upload-btn-wrapper {
      position: relative;
      overflow: hidden;
      display: inline-block;
      margin: 1rem 0;
      cursor: pointer;
      input[type='file'] {
        cursor: pointer;
        font-size: 100px;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
      }
      &:hover {
        .btn {
          background: linear-gradient(
            145deg,
            rgba($primary-color, 1),
            rgba($secondary-color, 1)
          );
        }
      }
      .btn {
        background: linear-gradient(
          145deg,
          rgba($primary-color, 0.75),
          rgba($secondary-color, 0.75)
        );
        color: white;
        border: none;
        padding: 1rem 2rem;
        border-radius: 1rem;
        font-size: 1.5rem;
        font-weight: normal;
      }
    }
  }
}
