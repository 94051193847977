@import url(https://rsms.me/inter/inter.css);
/**
 * GitHub Gist Theme
 * Author : Anthony Attard - https://github.com/AnthonyAttard
 * Author : Louis Barranqueiro - https://github.com/LouisBarranqueiro
 */
.hljs {
  display: block;
  background: white;
  padding: 0.5em;
  color: #333333;
  overflow-x: auto; }

.hljs-comment,
.hljs-meta {
  color: #969896; }

.hljs-variable,
.hljs-template-variable,
.hljs-strong,
.hljs-emphasis,
.hljs-quote {
  color: #df5000; }

.hljs-keyword,
.hljs-selector-tag,
.hljs-type {
  color: #d73a49; }

.hljs-literal,
.hljs-symbol,
.hljs-bullet,
.hljs-attribute {
  color: #0086b3; }

.hljs-section,
.hljs-name {
  color: #63a35c; }

.hljs-tag {
  color: #333333; }

.hljs-title,
.hljs-attr,
.hljs-selector-id,
.hljs-selector-class,
.hljs-selector-attr,
.hljs-selector-pseudo {
  color: #6f42c1; }

.hljs-addition {
  color: #55a532;
  background-color: #eaffea; }

.hljs-deletion {
  color: #bd2c00;
  background-color: #ffecec; }

.hljs-link {
  text-decoration: underline; }

.hljs-number {
  color: #005cc5; }

.hljs-string {
  color: #032f62; }

html {
  font-family: 'Inter', sans-serif; }

@supports (font-variation-settings: normal) {
  html {
    font-family: 'Inter var', sans-serif; } }

* {
  box-sizing: border-box; }

.presentation {
  height: 100vh;
  overflow: hidden;
  padding: 0rem 20rem;
  font-size: 36px; }
  .presentation .slide-content {
    position: relative;
    height: 100vh;
    background: #fff;
    color: #333;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column; }
    .presentation .slide-content .slide-inner {
      width: 100%; }
    .presentation .slide-content p {
      margin-bottom: 1.25em; }
    .presentation .slide-content h1,
    .presentation .slide-content h2,
    .presentation .slide-content h3,
    .presentation .slide-content h4,
    .presentation .slide-content h5 {
      font-family: "Inter", sans-serif;
      margin: 2.75rem 0 1rem;
      color: #222222;
      font-weight: bolder;
      line-height: 1.15;
      letter-spacing: -3.3px; }
    .presentation .slide-content h1 {
      margin-top: 0;
      font-size: 3.052em; }
    .presentation .slide-content h2 {
      font-size: 2.441em; }
    .presentation .slide-content h3 {
      font-size: 1.953em; }
    .presentation .slide-content h4 {
      font-size: 1.563em; }
    .presentation .slide-content h5 {
      font-size: 1.25em; }
    .presentation .slide-content small,
    .presentation .slide-content .text_small {
      font-size: 0.8em; }
    .presentation .slide-content img {
      object-fit: contain;
      display: block;
      max-width: 70%;
      max-height: 70%; }
      .presentation .slide-content img.emoji {
        display: inline-block;
        width: 36px;
        height: 36px; }
    .presentation .slide-content table {
      width: 100%;
      border-spacing: 0rem;
      text-align: left; }
      .presentation .slide-content table td,
      .presentation .slide-content table th {
        padding: 0.5rem; }
      .presentation .slide-content table thead {
        color: #444444; }
        .presentation .slide-content table thead th {
          border-bottom: 2px solid #eeeeee; }
      .presentation .slide-content table tbody tr:nth-child(even) {
        background-color: #f5f5f5; }
    .presentation .slide-content pre {
      background: #fafafa;
      padding: 1rem;
      border-left: 16px solid #eeeeee; }
  .presentation .current-slide {
    z-index: 10; }
  .presentation .next-slide {
    z-index: 1; }
  .presentation .next-slide-enter {
    -webkit-transform: translateY(-80vh);
            transform: translateY(-80vh);
    opacity: 0; }
  .presentation .next-slide-enter-active {
    opacity: 1;
    -webkit-transform: translateY(-100vh);
            transform: translateY(-100vh);
    -webkit-transition: all 1000ms ease;
    transition: all 1000ms ease; }
  .presentation .current-slide-exit {
    opacity: 1;
    -webkit-transform: translateY(0vh);
            transform: translateY(0vh); }
  .presentation .current-slide-exit-active {
    opacity: 0;
    -webkit-transform: translateY(-10vh);
            transform: translateY(-10vh);
    -webkit-transition: all 1000ms ease;
    transition: all 1000ms ease; }

html,
body {
  margin: 0;
  background: #fff;
  min-height: 100vh; }

.App {
  font-family: "Inter", sans-serif, sans-serif;
  font-weight: 500;
  font-size: 24px; }
  .App .start {
    font-family: "Inter", sans-serif;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    max-width: 1000px;
    align-items: center; }
    .App .start .flex {
      display: flex; }
      .App .start .flex .info {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        text-align: left; }
        .App .start .flex .info a {
          color: inherit;
          text-decoration: none;
          border-bottom: 2px solid #ff002eff; }
    .App .start #icon {
      height: 256px;
      width: 256px; }
    .App .start h1 {
      font-size: 5rem;
      margin: 0;
      margin-bottom: -1rem; }
    .App .start .upload-btn-wrapper {
      position: relative;
      overflow: hidden;
      display: inline-block;
      margin: 1rem 0;
      cursor: pointer; }
      .App .start .upload-btn-wrapper input[type='file'] {
        cursor: pointer;
        font-size: 100px;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0; }
      .App .start .upload-btn-wrapper:hover .btn {
        background: -webkit-linear-gradient(305deg, #ff002e, #2f07ff);
        background: linear-gradient(145deg, #ff002e, #2f07ff); }
      .App .start .upload-btn-wrapper .btn {
        background: -webkit-linear-gradient(305deg, rgba(255, 0, 46, 0.75), rgba(47, 7, 255, 0.75));
        background: linear-gradient(145deg, rgba(255, 0, 46, 0.75), rgba(47, 7, 255, 0.75));
        color: white;
        border: none;
        padding: 1rem 2rem;
        border-radius: 1rem;
        font-size: 1.5rem;
        font-weight: normal; }

